import React from 'react';
import Customers from './components/Customers';
import CustomersPerOrder from './components/CustomersPerOrder';
import OrderAbandoned from './components/OrderAbandoned';
import OrderDenied from './components/OrderDenied';
import OrderPreApproved from './components/OrderPreApproved';
import OrderError from './components/OrderError';
import OrderCoupons from './components/OrderCoupons';
import OrderPayments from './components/OrderPayments';
import Pix from './components/Pix';
import Orders from './components/Orders';
import OrderWithoutServices from './components/OrderWithoutServices';
import UploadBbReport from './components/UploadBbReport';
import Products from './components/Products';
import ProductsFavorites from './components/ProductsFavorites';
import ProductsFavoritesPerCustomer from './components/ProductsFavoritesPerCustomer';
import Newsletter from './components/Newsletter';
// import Searches from './components/Searches';
// import ProductViews from './components/ProductViews';

import { Container } from './styles';
import OrdersReversed from './components/OrdersReversed';
import OrdersByDeliveryType from './components/OrdersByDeliveryType';
import OrdersFromCollection from './components/OrdersFromCollection';
import Coupons from './components/Coupons';
import OrderLivelo from './components/OrderLivelo';
import CustomersRegisterOrder from './components/CustomersRegisterOrder';

const Reports: React.FC = () => {
  return (
    <Container>
      <h2>Relatórios</h2>
      <div style={{ marginTop: '1rem' }}>
        <h4>Pedidos</h4>
        <div className="row" style={{ marginTop: '0.5rem' }}>
          <div className="col-md-3">
            <Orders />
          </div>
          <div className="col-md-3">
            <OrderWithoutServices />
          </div>
          <div className="col-md-3">
            <OrderPayments />
          </div>
          <div className="col-md-3">
            <OrdersFromCollection />
          </div>
        </div>

        <div className="row" style={{ marginTop: '0.5rem' }}>
          <div className="col-md-3">
            <Pix />
          </div>
          <div className="col-md-3">
            <OrderAbandoned />
          </div>
          <div className="col-md-3">
            <OrderPreApproved />
          </div>
          <div className="col-md-3">
            <OrderLivelo />
          </div>
        </div>

        <div className="row" style={{ marginTop: '0.5rem' }}>
          <div className="col-md-3">
            <OrderDenied />
          </div>
          <div className="col-md-3">
            <OrderCoupons />
          </div>
          <div className="col-md-3">
            <OrderError />
          </div>
          <div className="col-md-3">
            <OrdersReversed />
          </div>
        </div>

        <div className="row" style={{ marginTop: '0.5rem' }}>
          <div className="col-md-3">
            <OrdersByDeliveryType />
          </div>
        </div>
      </div>
      <div style={{ marginTop: '1rem' }}>
        <h4>Clientes</h4>
        <div className="row" style={{ marginTop: '0.5rem' }}>
          <div className="col-md-3">
            <Customers />
          </div>
          <div className="col-md-3">
            <CustomersPerOrder />
          </div>
          <div className="col-md-3">
            <Newsletter />
          </div>
          <div className="col-md-3">
            <CustomersRegisterOrder />
          </div>
        </div>
      </div>
      <div style={{ marginTop: '1rem' }}>
        <h4>Produtos</h4>
        <div className="row" style={{ marginTop: '0.5rem' }}>
          <div className="col-md-3">
            <Products />
          </div>
          <div className="col-md-3">
            <ProductsFavorites />
          </div>
          <div className="col-md-3">
            <ProductsFavoritesPerCustomer />
          </div>
        </div>
        {/*
        <div className="row" style={{ marginTop: '0.5rem' }}>
          <div className="col-md-3">
            <ProductViews />
          </div>
          <div className="col-md-3">
            <Searches />
          </div>
        </div>
        */}
      </div>
      <div style={{ marginTop: '1rem' }}>
        <h4>Cupom</h4>
        <div className="row" style={{ marginTop: '0.5rem' }}>
          <div className="col-md-3">
            <Coupons />
          </div>
        </div>
      </div>
      <div style={{ marginTop: '1rem' }}>
        <h4>Cartão Lojas Império [BB]</h4>
        <div className="row" style={{ marginTop: '0.5rem' }}>
          <div className="col-md-3">
            <UploadBbReport />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Reports;
